import React from "react";

export const MX2LogoSVGMX2 = ({ height }) => {

    return (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" height={height} viewBox="23.86 25.522 440.808 208.633">
  <defs>
    <linearGradient id="linear-gradient" x1="539.85" y1="147.7" x2="539.85" y2="935.13" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#ffec00"/>
      <stop offset="12%" stopColor="#f8e500"/>
      <stop offset="29%" stopColor="#e8d100"/>
      <stop offset="49%" stopColor="#ccb200"/>
      <stop offset="72%" stopColor="#a58600"/>
      <stop offset="96%" stopColor="#754e00"/>
      <stop offset="100%" stopColor="#6d4501"/>
    </linearGradient>
    <filter id="drop-shadow-11" filterUnits="userSpaceOnUse">
      <feOffset dx="0" dy="35.3"/>
      <feGaussianBlur result="blur" stdDeviation="11.35"/>
      <feFlood floodColor="#000" floodOpacity="1"/>
      <feComposite in2="blur" operator="in"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <linearGradient id="linear-gradient-2" x1="149.718" y1="21.011" x2="139.92" y2="294.77" gradientUnits="userSpaceOnUse">
      <stop offset="0%" stopColor="#fffbcf"/>
      <stop offset="17%" stopColor="#ffec00"/>
    </linearGradient>
    <linearGradient id="linear-gradient-3" x1="539.92" y1="324.2" x2="539.92" y2="758.55" gradientTransform="translate(1079.84 1082.75) rotate(-180)" gradientUnits="userSpaceOnUse">
      <stop offset="0%" stopColor="#1a1a1a"/>
      <stop offset="100%" stopColor="#000"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" x1="539.92" y1="319.16" x2="539.92" y2="763.59" xlinkHref="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-5" x1="542.03" y1="401.99" x2="542.03" y2="586.17" xlinkHref="#linear-gradient-2"/>
  </defs>
  <g id="Layer_1" data-name="Layer 1">
  < path className="cls-1" fill="url(#linear-gradient-2)" d="M 149.718 221.011 L 149.718 102.121 L 117.238 221.011 L 103.198 221.011 L 71.308 102.121 L 71.308 221.011 L 35.318 221.011 L 35.318 39.551 L 82.128 39.551 L 110.218 144.021 L 139.188 39.551 L 185.128 39.551 L 185.128 221.011 L 149.728 221.011 L 149.718 221.011 Z M 288.398 221.011 L 264.408 158.441 L 240.708 221.011 L 200.038 221.011 L 243.048 128.511 L 202.378 39.551 L 243.048 39.551 L 264.408 98.861 L 285.768 39.551 L 326.728 39.551 L 285.768 128.241 L 329.068 221.011 L 288.398 221.011 Z M 340.478 221.011 L 340.478 192.441 C 403.388 118.441 409.818 108.101 409.818 89.601 C 409.818 77.361 402.208 67.291 389.628 67.291 C 377.048 67.291 366.808 73.821 358.908 87.421 L 335.498 67.021 C 348.368 48.251 365.048 36.821 391.378 36.821 C 425.028 36.821 446.968 57.501 446.968 88.781 C 446.968 112.991 435.268 132.581 389.328 190.801 L 447.848 190.801 L 447.848 221.001 L 340.468 221.001 L 340.478 221.011 Z"/>
  </g>
</svg>
  )
}
