import { APIUrl, getAPIHeaders } from './utils';
import { SearchTypes, SummarizeTextResponse } from '../types';

export const PromptSandboxBaseUrl = APIUrl.replace('api', 'llm.api').concat('v1');

export const getModels = async () => {
  const response = await fetch(`${PromptSandboxBaseUrl}/models`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const postSummarizeFromText = async (data: Object): Promise<SummarizeTextResponse> => {
  const response = await fetch(`${PromptSandboxBaseUrl}/summarize-text`, {
    method: 'POST',
    headers: getAPIHeaders(),
    body: JSON.stringify(data),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const postSummarizeFromFile = async (formData: FormData) => {
  const response = await fetch(`${PromptSandboxBaseUrl}/summarize-file`, {
    method: 'POST',
    headers: {
      Authorization: getAPIHeaders().Authorization,
    },
    body: formData,
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const postSummarizeRag = async (data: Object) => {
  const response = await fetch(`${PromptSandboxBaseUrl}/summarize-rag`, {
    method: 'POST',
    headers: {
      Authorization: getAPIHeaders().Authorization,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const shareToEmail = async (email: string, id: string) => {
  const qs = new URLSearchParams({ email, uid: id });
  const response = await fetch(`${PromptSandboxBaseUrl}/share-to-email?${qs}`, {
    method: 'POST',
    headers: {
      Authorization: getAPIHeaders().Authorization,
    },
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const getPDFExportLink = async (id: string) => {
  const qs = new URLSearchParams({ uid: id });
  const response = await fetch(`${PromptSandboxBaseUrl}/export-pdf?${qs}`, {
    method: 'POST',
    headers: {
      Authorization: getAPIHeaders().Authorization,
    },
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const getSearchResults = async (model: SearchTypes, searchInput: string, matter_name: string, document_id: string, detected_types: string[], matter_ids: string[] = []) => {
  let query = `search_type=${encodeURIComponent(model)}&q=${encodeURIComponent(searchInput)}`;
  if (matter_name) query += `&matter_mat_name=${encodeURIComponent(matter_name)}`;
  if (document_id) query += `&document_id=${encodeURIComponent(document_id)}`;
  if (detected_types.length > 0) query += `&detected_types=${encodeURIComponent(detected_types.join(','))}`;
  if (matter_ids.length > 0) query += `&matter_ids=${encodeURIComponent(matter_ids.join(','))}`;

  const response = await fetch(`${PromptSandboxBaseUrl}/elastic/search?${query}`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });

  try {
    const myJson = await response.json();
    return myJson;
  } catch (e) {
    console.error(e);
    return;
  }
};
