// @ts-nocheck
// Generally, we never want to use @ts-nocheck on any of our code.
// However, this is a code snippet straight from Pendo that should not be modified.
// And since it is not our code (it's Pendo's code), it is fine to not check this one file for TS errors.

import { current_conf } from '../../App';

export const injectPendoScript = (apiKey: string | undefined = current_conf.pendo?.apiKey) => {
  if (typeof window === 'undefined') return;

  if (document.querySelector(`script[src*="${apiKey}"]`)) {
    console.debug('Pendo script is already loaded');
    return;
  }

  // Start Original Pendo install script DO NOT MODIFY
  (function (apiKey) {
    (function (p, e, n, d, o) {
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; // Track added here
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = true;
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })(apiKey);
  // End of original Pendo install script

  console.debug('Pendo script injected');
};
