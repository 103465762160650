import React from 'react';
import { CircularProgress } from '@mui/material';

export const Loader = () => {
  return (
    <div className="flex justify-center my-2">
      <CircularProgress size={24} color="primary" />
    </div>
  );
};
