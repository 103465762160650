import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useAuth } from '../App';
export default function LogoutPage() {
  const { onLogout, token } = useAuth();
  if (token) {
    onLogout();
  }
  return <Typography variant="h3">Logout</Typography>;
}
