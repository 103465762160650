import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme } from '@mui/material/styles';

import Menu from '@mui/material/Menu';
import { MX2LogoSVGMX2 } from  '../media/MX2LogoSVGMX2';

import { MenuNavItemLink } from './RouterElements';

import { useAuth, ColorModeContext } from '../App';
import { useNavigate } from 'react-router-dom';

interface Props {
  toggleDrawer: () => void;
}

export default function NavBar(props: Props) {
  const toggleDrawer = props.toggleDrawer;
  useNavigate();
  const { token, store, onLogout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    toggleDrawer();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleDrawerToggle} ><MenuIcon/></IconButton>
          <Box sx={{pt:'5px'}}>
            <MX2LogoSVGMX2 height={50} />
          </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          <IconButton onClick={colorMode.toggleColorMode} color="inherit">
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          {store.loggedIn() && (
            <div>
              <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuNavItemLink onClick={handleClose} to="/account">
                  My account
                </MenuNavItemLink>
                <MenuNavItemLink aria-label="logout" onClick={handleClose} color="info" to="/logout">
                  <Button color="info" onClick={onLogout} endIcon={<LogoutIcon />}>
                    Logout
                  </Button>
                </MenuNavItemLink>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
