import { AccountData, VisitorData } from '../../types/pendo';

export const initializePendo = (visitorData: VisitorData, accountData?: AccountData) => {
  if (typeof window === 'undefined' || typeof window.pendo === 'undefined') {
    console.debug('Pendo is not available on the window. Maybe the script is not loaded yet.');
    return;
  }
  const initializeData: object = {
    visitor: { ...visitorData },
  };
  if (accountData) {
    Object.assign(initializeData, { account: { ...accountData } });
  }
  window.pendo.initialize(initializeData);

  console.debug('Pendo initialized with:', {
    visitor: visitorData,
    account: accountData,
  });
};
