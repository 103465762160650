export enum DetectedType {
  HealthInsuranceCard = 'Health Insurance Card',
  MedicalBill = 'Medical Bill',
  MedicalRecord = 'Medical Record',
  Offer = 'Offer',
  HIPAA = 'HIPAA',
  PoliceReport = 'Police Report',
  DriversLicense = 'Drivers License',
  Deposition = 'Deposition',
  InsuranceDeclaration = 'Insurance Declaration',
  Interrogatories = 'Interrogatories',
  ExplanationOfBenefits = 'Explanation of Benefits',
  LifeCarePlans = 'Life Care Plans',
  VehicleInsuranceCard = 'Vehicle Insurance Card',
  Demand = 'Demand',
}

export enum LitigationType {
  PersonalInjury = 'Personal Injury',
  PremisesLiability = 'Premises Liability',
}

export enum CaseType {
  AutomobileAccident = 'Automobile Accident',
  Trucking = 'Trucking',
  SlipAndFall = 'Slip and Fall',
  GeneralInjury = 'General Injury',
  GeneralLiability = 'General Liability',
  AnimalIncident = 'Animal Incident',
  NegligentSecurity = 'Negligent Security',
  TripAndFall = 'Trip and Fall',
  Salon = 'Salon',
  ChildrensRights = "Children's Rights",
  RapeAndMolestation = 'Rape & Molestation',
  FoodPoisoning = 'Food Poisoning',
  PersonalInjury = 'Personal Injury',
}

export enum CaseStatus {
  Settled = 'Settled',
  LawsuitFiled = 'Lawsuit Filed',
  Treating = 'Treating',
  Demand = 'Demand',
  Investigation = 'Investigation',
  PendingDemand = 'Pending Demand',
  NewCase = 'New Case',
  PendingLitigation = 'Pending Litigation',
  SettledUMPending = 'Settled-UM Pending',
  PendingTDRequest = 'Pending TD Request',
  Lien = 'Lien',
  Negotiation = 'Negotiation',
  SettledBIPending = 'Settled - BI Pending',
  Verdict = 'Verdict',
  Appeal = 'Appeal',
  DoNotFile = 'Do not file',
}

export enum MatterStage {
  PreSuit = 'Pre Suit',
  Litigation = 'Litigation',
}

export enum FileTypeName {
  PDF = 'PDF',
  WordDocx = 'Word (DOCX)',
  JpegImage = 'JPEG Image',
  PngImage = 'PNG Image',
  WordDoc = 'Word (DOC)',
  TextFile = 'Text File',
  RichTextFormat = 'Rich Text Format',
}

export const detectedTypes = Object.values(DetectedType);
export const litigationTypes = Object.values(LitigationType);
export const caseTypes = Object.values(CaseType);
export const caseStatuses = Object.values(CaseStatus);
export const matterStages = Object.values(MatterStage);
export const fileTypeNames = Object.values(FileTypeName);
export const fileTypeNameToValue: Record<FileTypeName, string> = {
  [FileTypeName.PDF]: "application/pdf",
  [FileTypeName.WordDocx]: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  [FileTypeName.JpegImage]: "image/jpeg",
  [FileTypeName.PngImage]: "image/png",
  [FileTypeName.WordDoc]: "application/msword",
  [FileTypeName.TextFile]: "text/plain",
  [FileTypeName.RichTextFormat]: "application/rtf",
};