import React, { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import { searchDocuments, suggestValues, Document } from '../../interactors/search-api';
import debounce from 'lodash.debounce';
import {
  Container,
  IconButton,
  Paper,
  InputLabel,
  FormControl,
  CardContent,
  OutlinedInput,
  InputAdornment,
  TextField,
  Box,
  Typography,
  CardHeader,
  Select,
  Tooltip,
  Button,
  MenuItem,
  Checkbox,
  ListItemText,
  Autocomplete,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import './SearchResults.css';
import { DocumentResult } from '../../components/search/DocumentResult';
import { NotificationContext } from '../../App';

import { detectedTypes, litigationTypes, caseTypes, caseStatuses, matterStages, fileTypeNames, fileTypeNameToValue } from '../../constants';
import { generateQuery } from './convert-filters-to-es-query';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DEBOUNCE_DELAY = 300;

export const SearchResultsV2 = (props) => {
  const [filtersOpen, setFiltersOpen] = useState(true);
  const { setToastMessage } = useContext(NotificationContext);
  const [selections, setSelections] = useState({});
  const [searchResults, setSearchResults] = useState<Document[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);

  // new filters
  const [searchInput, setSearchInput] = useState();

  const [matterName, setMatterName] = useState([]);
  const [matterId, setMatterId] = useState([]);
  const [parentOffice, setParentOffice] = useState(["Atlanta"]);
  const [referenceNumber, setReferenceNumber] = useState([]);
  const [litigation, setLitigation] = useState([]);
  const [caseType, setCaseType] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [matterStage, setMatterStage] = useState([]);
  const [myMatter, setMyMatter] = useState("all");

  const [datePickerField, setDatePickerField] = useState("");
  const [datePickerOperator, setDatePickerOperator] = useState("");
  const [datePickerValue, setDatePickerValue] = useState();

  const [docId, setDocId] = useState();
  const [detectedType, setDetectedType] = useState([]);
  const [fileType, setFileType] = useState([]);
  const [documentName, setDocumentName] = useState();

  const [referenceNumberSearch, setReferenceNumberSearch] = useState("");
  const [matterIdSearch, setMatterIdSearch] = useState("");
  const [matterNameSearch, setMatterNameSearch] = useState("");
  const [referenceNumberOptions, setReferenceNumberOptions] = useState<string[]>([]);
  const [matterIdOptions, setMatterIdOptions] = useState<string[]>([]);
  const [matterNameOptions, setMatterNameOptions] = useState<string[]>([]);

  const requestIdRef = useRef(0);

  const dateValue = useMemo(() => {
    return (datePickerField && datePickerOperator && datePickerValue) ? { "operator": datePickerOperator, "field": datePickerField, "value": datePickerValue } : null;
  }, [datePickerField, datePickerOperator, datePickerValue])

  const debouncedSuggestReferenceNumbers = useCallback(
    debounce((searchTerm: string) => {
      suggestValues("reference_number", searchTerm).then((result) => {
        const values = result || [];
        setReferenceNumberOptions(values);
      });
    }, DEBOUNCE_DELAY),
    []
  );

  const debouncedSuggestMatterName = useCallback(
    debounce((searchTerm: string) => {
      suggestValues("case_name", searchTerm).then((result) => {
        const values = result || [];
        setMatterNameOptions(values);
      });
    }, DEBOUNCE_DELAY),
    []
  );

  const debouncedSuggestMatterId = useCallback(
    debounce((searchTerm: string) => {
      suggestValues("matter_id", searchTerm).then((result) => {
        const values = result || [];
        setMatterIdOptions(values);
      });
    }, DEBOUNCE_DELAY),
    []
  );

  useEffect(() => {
    if (matterIdSearch) {
      debouncedSuggestMatterId(matterIdSearch);
    }
    return () => debouncedSuggestMatterId.cancel();
  }, [matterIdSearch, debouncedSuggestMatterId]);

  useEffect(() => {
    if (matterNameSearch) {
      debouncedSuggestMatterName(matterNameSearch);
    }
    return () => debouncedSuggestMatterName.cancel();
  }, [matterNameSearch, debouncedSuggestMatterName]);

  useEffect(() => {
    if (referenceNumberSearch) {
      debouncedSuggestReferenceNumbers(referenceNumberSearch);
    }
    return () => debouncedSuggestReferenceNumbers.cancel();
  }, [referenceNumberSearch, debouncedSuggestReferenceNumbers]);

  const numFiltersActive = [myMatter !== "all", searchInput, matterName.length > 0, documentName, matterId.length > 0, docId, referenceNumber.length > 0, detectedType.length > 0, litigation.length > 0, caseType.length > 0, caseStatus.length > 0, matterStage.length > 0, fileType.length > 0, dateValue].filter(Boolean).length;

  useEffect(() => {
    if (!numFiltersActive) {
      return;
    }

    const handler = setTimeout(() => {
      searchButtonClick();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [
    matterName,
    matterId,
    parentOffice,
    referenceNumber,
    litigation,
    caseType,
    caseStatus,
    matterStage,
    myMatter,
    docId,
    detectedType,
    fileType,
    documentName,
    numFiltersActive,
    dateValue,
    searchInput
  ]);

  const handleCheckResult = (id, data) => {
    if (!selections[id]) {
      setSelections({
        ...selections,
        [id]: data,
      });
      return;
    }
    const newSelections = { ...selections };
    delete newSelections[id];
    setSelections(newSelections);
  };

  useEffect(() => {
    props?.handleSelect?.(
      Object.values(selections).map((selection) => (
        {
          document_id: selection[0].id,
          version_id: selection[0].doc_version_id,
        }
      ))
    );
  }, [selections]);

  const handleQueryChange = (e) => setSearchInput(e.target.value);

  const handleDetectedTypeChange = (event) => {
    setDetectedType(event.target.value);
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleLitigationChange = (event) => {
    setLitigation(event.target.value);
  };

  const handleCaseTypeChange = (event) => {
    setCaseType(event.target.value);
  };

  const handleCaseStatusChange = (event) => {
    setCaseStatus(event.target.value);
  };

  const handleMatterStageChange = (event) => {
    setMatterStage(event.target.value);
  };

  const handleMatterNameChange = (event) => {
    setMatterName(event.target.value);
  };

  const handleMatterIdChange = (event) => {
    setMatterId(event.target.value);
  };

  const handleReferenceNumberChange = (event) => {
    setReferenceNumber(event.target.value);
  };

  const searchButtonClick = () => {
    setSelections({});
    setSearchResults([]);

    runQuery();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchButtonClick();
    }
  };

  const clearAllFilters = () => {
    setSearchInput("");
    setMatterName([]);
    setMatterId([]);
    setReferenceNumber([]);
    setLitigation([]);
    setCaseType([]);
    setCaseStatus([]);
    setMatterStage([]);
    setMyMatter('all');
    setDocId("");
    setDetectedType([]);
    setFileType([]);
    setDocumentName("");
    setDatePickerValue(null);
    setDatePickerOperator(null);
    setDatePickerField(null);

    setSearchResults([]); 
  };

  const runQuery = () => {
    setSearchResults([]); 
    setShowSpinner(true);

    // Generate a new request ID for this query
    const currentRequestId = requestIdRef.current + 1;
    requestIdRef.current = currentRequestId;

    const searchQuery = generateQuery(
      litigation,
      caseType,
      caseStatus,
      matterStage,
      detectedType,
      fileType.map((val) => fileTypeNameToValue[val]),
      myMatter === 'my_matters',
      matterName,
      matterId,
      referenceNumber,
      parentOffice,
      docId,
      documentName,
      searchInput,
      dateValue,
    );

    searchDocuments(searchQuery)
      .then((message) => {
        if (!message || currentRequestId !== requestIdRef.current) {
          return;
        }

        const results = message.nodes;
        setSearchResults(results);
        setShowSpinner(false);
      })
      .catch((error) => {
        if (currentRequestId !== requestIdRef.current) {
          return;
        }

        setToastMessage({ message: JSON.stringify(error), severity: 'error' });
        setShowSpinner(false);
      });
  };

  const renderFilters = () => {
    return (
      <>
        <CardHeader
          title={
            <Typography>
              {'Filters' + (numFiltersActive ? ` (${numFiltersActive})` : '')}
            </Typography>
          }
          sx={{
            height: "75px"
          }}
          action={
            <>
              {numFiltersActive > 0 && (<Button onClick={clearAllFilters}>
                Clear all
              </Button>)}
              <IconButton
                onClick={() => setFiltersOpen(!filtersOpen)}
                aria-label='expand'
                size='small'
              >
                {filtersOpen ? <KeyboardArrowUpIcon />
                  : <KeyboardArrowDownIcon />}
              </IconButton>
            </>
          }
        />
        <Collapse in={filtersOpen}>
          <CardContent className="filters-container">
            <FormControl className="filter-item">
              <Select
                labelId="matters-label"
                value={myMatter || ""}
                onChange={(e) => setMyMatter(e.target.value)}
              >
                <MenuItem value="all">
                  All Matters
                </MenuItem>
                <MenuItem value="my_matters">
                  My Matters
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="filter-item">
              <Autocomplete
                options={matterNameOptions}
                value={matterName}
                multiple
                limitTags={1}
                inputValue={matterNameSearch}
                onInputChange={(event, newInputValue) => setMatterNameSearch(newInputValue)}
                onChange={(event, newValue) => {
                  setMatterName(newValue);
                  handleMatterNameChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={!matterName?.length && !matterNameSearch ? "Matter Name" : ""}
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                  />
                )}
              />
            </FormControl>
            <FormControl className="filter-item">
              <Autocomplete
                options={matterIdOptions}
                value={matterId}
                multiple
                limitTags={1}
                inputValue={matterIdSearch}
                onInputChange={(event, newInputValue) => setMatterIdSearch(newInputValue)}
                onChange={(event, newValue) => {
                  setMatterId(newValue);
                  handleMatterIdChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={!matterId?.length && !matterIdSearch ? "Matter Id" : ""}
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                  />
                )}
              />
            </FormControl>
            <FormControl variant="outlined" className="filter-item">
              <Autocomplete
                options={referenceNumberOptions}
                value={referenceNumber}
                multiple
                limitTags={1}
                inputValue={referenceNumberSearch}
                onInputChange={(event, newInputValue) => setReferenceNumberSearch(newInputValue)}
                onChange={(event, newValue) => {
                  setReferenceNumber(newValue);
                  handleReferenceNumberChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={!referenceNumber?.length && !referenceNumberSearch ? "Reference number" : ""}
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                  />
                )}
              />
            </FormControl>
            <FormControl className="filter-item">
              {!litigation.length && (<InputLabel id="matter-litigation-type" shrink={false}>Matter Litigation Type</InputLabel>)}
              <Select
                labelId='matter-litigation-type'
                multiple
                value={litigation}
                onChange={handleLitigationChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {litigationTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={litigation.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="filter-item">
              {!caseType.length && (<InputLabel id="matter-type" shrink={false}>Matter Type</InputLabel>)}
              <Select
                multiple
                value={caseType}
                labelId="matter-type"
                onChange={handleCaseTypeChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {caseTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={caseType.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip placement='top' title={"You can only search for documents from matters with Atlanta as the parent office. More offices coming soon!"}>
              <FormControl className="filter-item">
                <Select
                  labelId="matter-parent-office"
                  value={parentOffice}
                  disabled
                  renderValue={(selected) => selected.join(', ')}
                >
                  {["Atlanta"].map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox checked={parentOffice.indexOf(type) > -1} />
                      <ListItemText primary={type} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
            <FormControl className="filter-item">
              {!caseStatus.length && (<InputLabel id="matter-status" shrink={false}>Matter Status</InputLabel>)}
              <Select
                labelId="matter-status"
                multiple
                value={caseStatus}
                onChange={handleCaseStatusChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {caseStatuses.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={caseStatus.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="filter-item">
              {!matterStage.length && (<InputLabel id="matter-stage" shrink={false}>Matter Stage</InputLabel>)}
              <Select
                labelId="matter-stage"
                multiple
                value={matterStage}
                onChange={handleMatterStageChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {matterStages.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={matterStage.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="filter-item">
              {!docId?.length && (<InputLabel shrink={false}>Document ID</InputLabel>)}
              <OutlinedInput
                type='text'
                value={docId}
                onChange={(e) => setDocId(e.target.value)}
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='clear document'
                      onClick={() => setDocId('')}
                      edge='end'
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl className="filter-item">
              {!documentName?.length && (<InputLabel shrink={false}>Document Name</InputLabel>)}
              <OutlinedInput
                type='text'
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='clear document name'
                      onClick={() => setDocumentName('')}
                      edge='end'
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl variant='outlined' className="filter-item">
              {!detectedType.length && (<InputLabel id="document-detected-type" shrink={false}>Document Detected Type</InputLabel>)}
              <Select
                labelId="document-detected-type"
                multiple
                value={detectedType}
                onChange={handleDetectedTypeChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {detectedTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={detectedType.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" width="100%" justifyContent={"space-between"} gap={"10px"} sx={(theme) => ({ backgroundColor: theme.palette.background.icon })} >
              <FormControl className="filter-item">
                {!datePickerField && (<InputLabel id="date-filter-field" shrink={false}>Date</InputLabel>)}
                <Select
                  labelId="date-filter-field"
                  value={datePickerField}
                  onChange={(e) => setDatePickerField(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="document_modified">
                    Document updated
                  </MenuItem>
                  <MenuItem value="document_created">
                    Document uploaded
                  </MenuItem>
                  <MenuItem value="matter_modified">
                    Matter updated
                  </MenuItem>
                  <MenuItem value="matter_created">
                    Matter created
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className="filter-item">
                {!datePickerOperator && (<InputLabel id="date-filter-operator" shrink={false}>Operator</InputLabel>)}
                <Select
                  labelId="date-filter-operator"
                  value={datePickerOperator}
                  onChange={(e) => setDatePickerOperator(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="equals">
                    On
                  </MenuItem>
                  <MenuItem value="gte">
                    After
                  </MenuItem>
                  <MenuItem value="lte">
                    Before
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className="filter-item">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']} sx={{ paddingTop: "0px" }}>
                    <DatePicker sx={{ width: "100%" }} onChange={setDatePickerValue} value={datePickerValue} />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
            </Box>
            <FormControl variant='outlined' className="filter-item">
              {!fileType.length && (<InputLabel id="document-file-type" shrink={false}>Document File Type</InputLabel>)}
              <Select
                labelId="document-file-type"
                multiple
                value={fileType}
                onChange={handleFileTypeChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {fileTypeNames.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={fileType.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Collapse >
      </>
    );
  };

  return (
    <Container className='outer'>
      {/* Disclaimer Banner */}
      <Alert
        severity="info" color="warning"
        variant="filled"
        sx={{
          mb: 2,
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '1.0rem', // Increase the font size
          padding: '16px 24px', // Add padding for better spacing
          alignItems: 'center', // Vertically center the content
          justifyContent: 'center', // Horizontally center the text
        }}
      >
        Smart Search is currently in Beta. Searchable documents are currently limited to specific offices and attorneys.
        <br />
        Your feedback is welcome as we continue to improve your search experience. Share feedback <a href="https://feedback.forthepeople.com/litify-suggestions?selectedCategory=smart-search">here</a>
      </Alert>
      <Paper elevation={4} sx={{ width: '100%' }}>
        <CardContent>
          <OutlinedInput
            id='main-input'
            value={searchInput}
            onChange={handleQueryChange}
            onKeyDown={handleKeyDown}
            placeholder='Search'
            variant='outlined'
            fullWidth
            endAdornment={
              <InputAdornment position='end'>
                <IconButton color='info' aria-label='Search' onClick={searchButtonClick} edge='end'>
                  {showSpinner && <RefreshIcon className='spin' />}
                  {!showSpinner && <SearchIcon />}
                </IconButton>
              </InputAdornment>
            } />
        </CardContent>
        {renderFilters()}
      </Paper>
      {showSpinner && (<Box display={"flex"} marginTop="10px" marginBottom="10px" justifyContent={"center"}>
        <RefreshIcon className='spin' fontSize='large' />
      </Box>)}
      {!showSpinner && !searchResults.length && numFiltersActive > 0 && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          No results matching your search query.
        </Alert>
      )}
      <Paper elevation={4}>
        {searchResults.map?.((data, index) => (
          <Paper elevation={6}>
            <DocumentResult
              key={index}
              result={data}
              onCheck={props.handleSelect ? () => handleCheckResult(index, data) : undefined}
              checked={!!selections[index]}
            />
          </Paper>
        ))}
      </Paper>
    </Container>
  );
};
