import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import { AppStatus, actions, useAppDispatch } from '../../store/EnterpriseSearch';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface SearchInputProps {
  onSearch: (query: string) => void;
  value: string;
  appStatus: AppStatus;
}

export default function SearchInput({ onSearch, value, appStatus }: SearchInputProps) {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<string>(value);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (query.length > 0) {
      onSearch(query);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value);

  const handleReset = () => {
    setQuery('');
    dispatch(actions.reset());
  };

  useEffect(() => {
    setQuery(value);
  }, [value]);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '32px' }}>Expert Database Search</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="What is on your mind?"
            value={query}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {appStatus === AppStatus.Idle ? (
                    <Button type="submit" variant="contained" color="primary" disabled={!query.length} sx={{ ml: 1 }}>
                      <SendIcon />
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={handleReset}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        borderColor: 'divider',
                        '&:hover': {
                          bgcolor: 'background.secondary',
                          borderColor: 'text.primary',
                        },
                      }}
                    >
                      <RefreshIcon sx={{ mr: 1 }} /> Start over
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </form>
    </>
  );
}
