import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Card } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';


interface DocResolverProps {
  name: string;
  size: number;
  state: string;
}

const STATE_TO_MESSAGE = {
  "VALIDATION": "Validating",
  "READY": "Ready",
  "CHUNKING": "Processing",
  "OCR": "OCR",
  "STORING_DOCUMENT": "Storing",
  "UPLOADING": "Uploading",
  "ERROR": "Error",
}


export const DocResolver = ({ size, name, state }: DocResolverProps) => {
  return (
    <Box>
      <FileBox name={name} />
      <div>
        <Typography variant="body2">{(size / 1024).toFixed(2)}kb{` • ${STATE_TO_MESSAGE[state]}`}</Typography>
      </div>
    </Box>
  )
};

export const FileBox = ({ name, documentId, deleteDocument }: { name: string; documentId?: string; deleteDocument?: (documentId: string) => undefined }) => {
  return (
    <>
      <Card elevation={2} sx={{ "padding": 0 }}>
        <Box display="flex" padding={"16px 10px"} height={"100%"} alignItems={"center"}>
          <Box marginRight={"8px"} display="flex" alignItems={"center"}>
            <DescriptionIcon />
          </Box>
          {name}
        </Box>
      </Card>
      {documentId && deleteDocument ? (<Box display="flex" alignItems="center"><IconButton sx={{ marginLeft: "4px" }}><DeleteIcon onClick={() => deleteDocument(documentId)} /></IconButton></Box>) : undefined}
    </>
  )
}