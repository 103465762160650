import * as React from 'react';
import ListItem, {ListItemProps }from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem ,{ MenuItemProps } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
  Route,
  Routes,
  MemoryRouter,
  useLocation,
} from 'react-router-dom';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

interface MenuItemLinkProps extends MenuItemProps {
  icon?: React.ReactElement;
  to: string;
}
 
interface MenuItemNavLinkProps extends MenuItemProps {
  icon?: React.ReactElement;
  to: string;
}

export const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  function Link(itemProps, ref) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
  },
);

export const NavLink = React.forwardRef<HTMLAnchorElement, RouterNavLinkProps>(
  function NavLink(itemProps, ref) {
    const theme = useTheme();
    return <RouterNavLink 
      ref={ref} 
      {...itemProps} 
      style={({ isActive, isPending }) =>{
        return {
          color: isActive ? theme.palette.text.secondary : "gray",
        }}} 
      role={undefined} />;
  },
);
  
 export function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to } = props;
  
  return (
    <li>
    <ListItem component={Link} to={to}>
    {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText sx={{color:"gray"}} primary={primary} />
    </ListItem>
    </li>
  );
}
  

export function MenuItemLink(props: MenuItemLinkProps) {
  return (
    <MenuItem component={Link} {...props} />
  );
}
  
export function MenuNavItemLink(props: MenuItemNavLinkProps) {
  const { icon, children } = props
  return (
    <MenuItem component={NavLink}  {...props}>
        {/* {icon ? <ListItemIcon>{icon}</ListItemIcon> : null} */}
        <ListItemText>
          <Typography  sx={{textTransform:'uppercase'}}>
            {children}
          </Typography>
        </ListItemText>
    </MenuItem>
  );
}
  