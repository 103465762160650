import React from 'react';
import Markdown from 'markdown-to-jsx';
import { ChatMessageType } from '../../../types';
import { Loader } from '../loader';
import { Sources } from './sources';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ChatIcon from '@mui/icons-material/Chat';
import { Box, Paper, Stack } from '@mui/material';

type ChatMessageProps = Omit<ChatMessageType, 'id'> & {
  onSourceClick: (source: string) => void;
};

export const ChatMessage: React.FC<ChatMessageProps> = ({ content, isHuman, sources, loading, onSourceClick }) => {
  const messageIcon = isHuman ? (
    <Box sx={{ alignSelf: 'flex-start', mt: 1 }}>
      <PersonOutlineIcon sx={{ width: 24, height: 24 }} />
    </Box>
  ) : (
    <Box sx={{ alignSelf: 'flex-start', mt: 1 }}>
      <ChatIcon sx={{ width: 24, height: 24, color: 'primary.main' }} />
    </Box>
  );

  return (
    <Stack spacing={6}>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: isHuman ? 'flex-end' : 'flex-start', width: '100%', mb: 6 }}>
        {!isHuman && messageIcon}
        <Paper
          className="speech-bubble"
          sx={{
            p: 3,
            borderRadius: 2,
            ...(isHuman
              ? {
                  bgcolor: 'primary.main',
                  color: 'background.paper',
                  borderBottomRightRadius: 0,
                  mt: 2,
                }
              : {
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  borderBottomLeftRadius: 0,
                  border: 2,
                  borderColor: 'divider',
                  mt: 2,
                }),
          }}
        >
          <Box
            sx={{
              whiteSpace: 'pre-wrap',
              lineHeight: 2,
              '& p': {
                mb: 3,
                '&:last-child': {
                  mb: 0,
                },
              },
            }}
          >
            <Markdown>{content || ''}</Markdown>
          </Box>
          {loading && <Loader />}
        </Paper>
        {isHuman && messageIcon}
      </Box>

      {sources && sources.length > 0 && (
        <Box sx={{ display: 'flex', gap: 2, mt: 6 }}>
          {messageIcon}
          <Sources sources={sources} onSourceClick={onSourceClick} />
        </Box>
      )}
    </Stack>
  );
};
