import { APIUrl, getAPIHeaders } from './utils';
import { API } from '../types/APIs';

export const APIURL = APIUrl.replace('api', 'site.api');

export const getTableauToken = async () => {
  const response = await fetch(`${APIURL}tableau/token`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const getTableauDashboards = async () => {
  const response = await fetch(`${APIURL}tableau/dashboards`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};

export const getAPIs = async (): Promise<API[]> => {
  const response = await fetch(`${APIURL}apis`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
};
