import React from 'react';
import { Box, SxProps } from '@mui/material';

export type SourceIconType = {
  sx?: SxProps;
  icon: 'confluence' | 'docs' | 'dropbox' | 'excel' | 'onedrive' | 'pdf' | 'sharepoint' | 'sheets' | 'slides' | 'teams' | 'sql_server' | 'word' | 'github' | 'faq' | string;
};

export const SourceIcon: React.FC<SourceIconType> = ({ sx, icon }) => (
  <Box component="span" sx={sx}>
    <Box component="img" src={`/images/${icon}.png`} alt={icon} sx={{ width: 24, height: 24 }} />
  </Box>
);
