import React from 'react';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { SourceType } from '../../types';
import { SearchResult } from './search_result';
import { Box, Typography, Stack } from '@mui/material';

interface SearchResultsProps {
  results: SourceType[];
  toggleSource: (source: string) => void;
}

export const SearchResults: React.FC<SearchResultsProps> = ({ results, toggleSource }) =>
  results?.length ? (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <LightbulbIcon color="primary" />
        <Typography variant="h6" color="text.secondary">
          Search results
        </Typography>
      </Box>
      <Stack spacing={2}>{results?.map((result) => <SearchResult key={result.name} toggleSource={toggleSource} {...result} />)}</Stack>
    </Box>
  ) : null;
