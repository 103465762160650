import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { getAPIs } from '../interactors/site';
import { API } from '../types/APIs';

import { current_conf, useAuth } from '../App';

export default function APIs() {
  const { token } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryApi = searchParams.get('api') || '';
  const [apis, setApis] = React.useState<Record<string, API>>({});
  const [apiName, setApiName] = React.useState(queryApi);


  useEffect(() => {
    getAPIs().then(apis => {
      setApis(apis.reduce((acc, api) => {
        acc[api.id] = api;
        return acc;
      }, {} as Record<string, API>))
    });
  }, []);

  const url = React.useMemo(() => {
    if (!apis?.[apiName]) return '';
    const baseUrl = `https://${apis[apiName].domain_name}`;
    return `${baseUrl}/openapi.json`;
  }, [apis, apiName])

  const handleChangeApiName = (event: SelectChangeEvent) => {
    if (!event.target.value) return
    setApiName(event.target.value);
    setSearchParams({ api: event.target.value });
  };

  SwaggerUI.config.preautherizedApiKey = token;

  // @ts-expect-error missing the type of this object
  const complete = (swaggerUi) => {
    swaggerUi.preauthorizeApiKey('HTTPBearer', token);
  };

  // @ts-expect-error missing the type of this object
  const requestInterceptor = (req) => {
    req.headers['Authorization'] = `Bearer ${token}`
    return req
  };

  const renderAPIsSelect = () => {
    const value = apiName in apis ? apiName : ''
    return (
      <Select value={value} onChange={handleChangeApiName}>
        <MenuItem key='none' value="">
          { Object.keys(apis).length ? 'None' : 'Loading...' }
        </MenuItem>,
        {
          Object.entries(apis).map(
              ([, api]) =>
                <MenuItem key={api.id} value={api.id}>
                  {api.public_name}
                </MenuItem>
          )
        }
      </Select>
    )
  };

  return (
    <Paper elevation={2} sx={{ p: 2, pt: 5 }}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Typography variant="h3" color="primary" >
          APIs <small>env:{current_conf.env}</small>
        </Typography>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="api_name">API</InputLabel>
          {renderAPIsSelect()}
        <FormHelperText>The API Name to fetch</FormHelperText>
      </FormControl>
      <Box id="api_docs"> {url ? <SwaggerUI id="api_docs" url={url} onComplete={complete} requestInterceptor={requestInterceptor} /> : null}</Box>
    </Paper>
  );
}
