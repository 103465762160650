import React, { useEffect, useRef, useState } from 'react';
import { SourceIcon } from './source_icon';
import { SourceType } from '../../types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography, IconButton, Paper, Link, Stack } from '@mui/material';

interface SearchResultProps extends SourceType {
  toggleSource: (source: string) => void;
}

const TITLE_HEIGHT = 59;

const extractPathUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const path = decodeURIComponent(parsedUrl.pathname);
    const parts = path.split('/').filter(Boolean);

    if (parts.length > 1) {
      const pathUrlParts = parts.slice(0, -1).join('/');
      const layoutsIndex = pathUrlParts.indexOf('_layouts/15');
      if (layoutsIndex !== -1) {
        return `${parsedUrl.origin}/${pathUrlParts.substring(0, layoutsIndex).replace(/\/$/, '')}`;
      }
      return `${parsedUrl.origin}/${pathUrlParts}`;
    }
  } catch (error) {
    console.error('Invalid URL', error);
  }
  return null;
};

export const SearchResult: React.FC<SearchResultProps> = ({ name, icon, url, summary, updated_at, confidence, expanded, toggleSource }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [blockHeight, setBlockHeight] = useState<string | number>(0);
  const onToggle = (event) => !event.target.href && toggleSource(name);
  const updatedAtDate = new Date(updated_at || '');
  const pathUrl = extractPathUrl(url);

  useEffect(() => {
    const blockHeight = ref.current?.clientHeight;
    if (blockHeight) {
      setBlockHeight(blockHeight);
    }
  }, [summary]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 2 }}>
      <Paper
        onClick={onToggle}
        elevation={expanded ? 3 : 1}
        sx={{
          cursor: 'pointer',
          overflow: 'hidden',
          transition: 'transform 0.3s, box-shadow 0.3s',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: 4,
          },
          bgcolor: 'background.paper',
          color: 'text.primary',
          borderColor: 'divider',
          borderRadius: 2,
          height: expanded ? 'auto' : TITLE_HEIGHT,
        }}
      >
        <Box sx={{ p: 2 }} data-source={name} ref={ref}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <SourceIcon icon={icon} sx={{ borderRadius: 1 }} />
            <Typography variant="h6" color="primary" noWrap>
              {name}
            </Typography>
            <Box sx={{ ml: 'auto' }}>
              <IconButton>
                <KeyboardArrowDownIcon
                  sx={{
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Stack spacing={1}>
            {expanded && summary && (
              <Stack spacing={1}>
                {summary.map((text, index) => (
                  <Box key={index}>
                    <Typography variant="body2" color="text.secondary">
                      Summary
                    </Typography>
                    <Typography variant="body2">{text}</Typography>
                  </Box>
                ))}
              </Stack>
            )}
            <Typography variant="body2" color="text.secondary" noWrap>
              URL:{' '}
              <Link href={url} target="_blank" rel="noreferrer">
                {url}
              </Link>
            </Typography>
            {pathUrl && (
              <Link href={pathUrl} target="_blank" rel="noreferrer" sx={{ fontSize: '1em' }}>
                Show More Like This
              </Link>
            )}
          </Stack>
        </Box>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
        {confidence && (
          <Typography variant="caption" color="text.secondary" sx={{ alignSelf: 'flex-start', mt: 1 }}>
            Confidence Score: {confidence.toFixed(1)}%
          </Typography>
        )}
        {updated_at && (
          <Typography variant="caption" color="text.secondary" sx={{ alignSelf: 'flex-end', mt: 1 }}>
            {`LAST UPDATED ${updatedAtDate.toLocaleDateString('common', {
              month: 'short',
            })} ${updatedAtDate.toLocaleDateString('common', {
              day: 'numeric',
            })}, ${updatedAtDate.getFullYear()}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
