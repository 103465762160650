import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Link,
  Checkbox,
  Grid,
  Typography,
  useTheme, // Import useTheme to access the current theme
} from '@mui/material';
import { Document } from '../../interactors/search-api';

const generateDocumentLink = (documentId: string, versionId: string) => `https://forthepeople.lightning.force.com/lightning/n/litify_docs__File_Details?c__id=${documentId}&c__version_id=${versionId}`;
const generateMatterLink = (matterId: string) => `https://forthepeople.lightning.force.com/lightning/r/litify_pm__Matter__c/${matterId}/view`;

export const DocumentResult = (props: { result: Document; checked: boolean; onCheck?: () => void }) => {
  const theme = useTheme(); // Use the theme to dynamically get colors

  const docId = props.result.id
  const docVersionId = props.result.doc_version_id
  const matterId = props.result.matter?.id
  const documentLink = generateDocumentLink(docId, docVersionId)
  const matterLink = matterId ? generateMatterLink(matterId) : ""

  return (
    <Card elevation={2} sx={{ m: 4, backgroundColor: theme.palette.background.paper }}>
      <CardHeader
        sx={{ backgroundColor: theme.palette.background.paper }}
        titleTypographyProps={{ color: theme.palette.text.primary }}
        title={
          <>
            {
              props.onCheck && (
                <Checkbox
                  checked={props.checked}
                  onChange={props.onCheck}
                />
              )
            }
            <Link
              color="inherit"
              href={documentLink}
              target="_blank"
              className="linked-title">
              {props.result.name}
            </Link>
          </>
        }
        subheader={
          <>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} sm={6} md={6} zeroMinWidth>
                <Link
                  color="inherit"
                  href={matterLink}
                  target="_blank"
                  className="linked-title">
                  <Typography variant="body2" noWrap style={{ wordBreak: "break-word" }}>
                    Matter Name: {props.result.matter?.case_display_name ?? "N/A"}
                  </Typography>
                  <Typography variant="body2" noWrap style={{ wordBreak: "break-word" }}>
                    Matter ID: {props.result.matter?.id ?? "N/A"}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={6} sm={6} md={6} zeroMinWidth>
                <Typography variant="body2" noWrap style={{ wordBreak: "break-word" }}>
                  Detected Type: {props.result.detected_type ?? "N/A"}
                </Typography>
                <Typography variant="body2" noWrap style={{ wordBreak: "break-word" }}>
                  Uploaded: {new Date(props.result.created_date).toLocaleString("en")}
                </Typography>
                <Typography variant="body2" noWrap style={{ wordBreak: "break-word" }}>
                  Last modified :{new Date(props.result.last_modified_date).toLocaleString("en")}
                </Typography>
              </Grid>
            </Grid>
          </>}>
      </CardHeader>
      <CardContent>
        <Typography>
          {props.result.first_page_content ?? "N/A"}
        </Typography>
      </CardContent>
    </Card >
  )
};
