import * as React from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { useSearchParams } from 'react-router-dom';
import NavDrawer from './NavDrawer';
import NavBar from './NavBar';
import { ToastParams } from '../types';
import { useState, useContext } from 'react';

import { ColorModeContext } from '../App';
import { NotificationContext } from '../App';

const drawerWidth = 240;

export default function PageLayout({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [toastMessage, setToastMessage] = React.useState<ToastParams | null>(null);

  const { toggleColorMode } = useContext(ColorModeContext);
  const theme = useTheme();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setOpenDrawer(!openDrawer);
      setMobileOpen(!mobileOpen);
    }
  };

  const renderToast = () => {
    return (
      <Snackbar
        open={!!toastMessage?.message}
        autoHideDuration={6000}
        onClose={() => setToastMessage(null)}
      >
        <Alert
          severity={toastMessage?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {toastMessage?.message}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <NotificationContext.Provider value={{ setToastMessage }}>
      <Box sx={{ display: 'flex' }} >
        {searchParams.get('embed') ? null : (
          <Box component="nav" sx={{ width: openDrawer && drawerWidth }}>
            <Drawer
              variant="persistent"
              open={openDrawer}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerToggle}
              // on
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  bgcolor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  // bgcolor: 'black',
                },
              }}
            >
              <NavDrawer />
            </Drawer>
          </Box>
        )}
        <Box
          component="main"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            flexGrow: 1,
          }}
        >
          {searchParams.get('embed') ? null : <NavBar toggleDrawer={() => handleDrawerToggle()} />}
          {children}
        </Box>
        {renderToast()}
      </Box>
    </NotificationContext.Provider>
  );
}
