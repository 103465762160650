import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { actions, AppStatus, thunkActions, useAppDispatch, useAppSelector, store } from '../../store/EnterpriseSearch';
import { Chat } from '../../components/EnterpriseSearch/chat/chat';
import SearchInput from '../../components/EnterpriseSearch/search_input';
import ChatIcon from '@mui/icons-material/Chat';
import { SearchResults } from '../../components/EnterpriseSearch/search_results';
import { Loader } from '../../components/EnterpriseSearch/loader';
import { GlobalStateProvider } from '../../store/EnterpriseSearch';
import { Box, Button, Typography, Paper, Stack } from '@mui/material';

const EnterpriseSearchContent = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.status);
  const sources = useAppSelector((state) => state.sources);
  const [summary, ...messages] = useAppSelector((state) => state.conversation);
  const hasSummary = useAppSelector((state) => !!state.conversation?.[0]?.content);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearch = (query: string) => {
    dispatch(thunkActions.search(query));
  };

  const handleSendChatMessage = (query: string) => {
    dispatch(thunkActions.askQuestion(query));
  };

  const handleAbortRequest = () => {
    dispatch(thunkActions.abortRequest());
  };

  const handleToggleSource = (name: string) => {
    dispatch(actions.sourceToggle({ name }));
  };

  const handleSourceClick = (name: string) => {
    dispatch(actions.sourceToggle({ name, expanded: true }));
    setTimeout(() => {
      document.querySelector(`[data-source="${name}"]`)?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  const suggestedQueries = [
    'What experts do we have on the effects of sleep deprivation on driver awareness?',
    'Has Doctor Richard Strain ever claimed that a herniation is a permanent injury?',
    'Has Dr. Ying Lu discussed delta-v force?',
    'What are the common questions asked in defense cross-examination of Dr. Amir Ahmadian?',
    'Has Nicole Bonaparte testimony ever been stricken by a court?',
  ];

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        p: 4,
      }}
    >
      <SearchInput onSearch={handleSearch} value={searchQuery} appStatus={status} />

      {status === AppStatus.Idle ? (
        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ChatIcon /> Common questions
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {suggestedQueries.map((query) => (
              <Button
                key={query}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setSearchQuery(query);
                  handleSearch(query);
                }}
                sx={{
                  justifyContent: 'flex-start',
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  boxShadow: 1,
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  borderColor: 'divider',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: 3,
                    borderColor: 'divider',
                  },
                }}
              >
                {query}
              </Button>
            ))}
          </Box>
        </Box>
      ) : (
        <>
          {hasSummary ? (
            <Stack spacing={4} sx={{ mt: 4, flexGrow: 1, position: 'relative' }}>
              <Chat status={status} messages={messages} summary={summary} onSend={handleSendChatMessage} onAbortRequest={handleAbortRequest} onSourceClick={handleSourceClick} />
              <SearchResults results={sources} toggleSource={handleToggleSource} />
            </Stack>
          ) : (
            <Paper
              elevation={3}
              sx={{
                mt: 6,
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <Loader />
              <Typography variant="body2" color="text.secondary">
                Looking that up for you...
              </Typography>
            </Paper>
          )}
        </>
      )}
    </Box>
  );
};

export const EnterpriseSearch = () => (
  <Provider store={store}>
    <GlobalStateProvider>
      <EnterpriseSearchContent />
    </GlobalStateProvider>
  </Provider>
);
