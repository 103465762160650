import React, { useEffect } from 'react';
import { redirect } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useAuth } from '../App';

export default function ForthepeoplePage() {
  const { token, onLogin } = useAuth();
  useEffect(() => {
    onLogin();
    if (token) {
      redirect('/');
    }
  });
  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Typography variant="h3" color="primary">
        MX2
      </Typography>
    </Paper>
  );
}
