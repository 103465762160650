import React, { useRef, useState } from 'react';
import { useAuth } from '../App';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import CopyAll from '@mui/icons-material/CopyAll';

export default function CopyToken() {
  const [copySuccess, setCopySuccess] = useState('');
  const { token } = useAuth();

  async function copyToClipboard() {
    const blob = new Blob([token || ''], { type: 'text/plain' });
    const clipboardItemInput = new ClipboardItem({ 'text/plain': blob });
    await navigator.clipboard.write([clipboardItemInput]);
    setCopySuccess('Copied!');
  }

  return (
    <FormControl sx={{ m: 1, width: '50%' }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">Token</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={'password'}
        value={token || ''}
        endAdornment={
          <InputAdornment position="end">
            <IconButton color='info' aria-label="copy token to clipboard" onClick={copyToClipboard} edge="end">
              <CopyAll />
            </IconButton>
          </InputAdornment>
        }
        label={copySuccess}
        color={copySuccess ? 'success' : 'primary'}
      />
    </FormControl>
  );
}
