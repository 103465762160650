import AuthStore from '../store/auth';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const baseurl = window.location.hostname;
export const localurl = window.location.host;

export const getAPIUrl = () => {
  if (isLocalhost) {
    return `https://api.mx2.dev/`;
  } else {
    return `https://api.${baseurl}/`;
  }
};

export const getFrontendUrl = () => {
  if (isLocalhost) {
    return `http://${localurl}/`;
  } else {
    return `https://${baseurl}/`;
  }
};

export const APIUrl: string = getAPIUrl();

export const APIHeaders = {
  'Content-Type': 'application/json',
};

export const getAPIHeaders = () => {
  return {
    ...APIHeaders,
    Authorization: `Bearer ${AuthStore.accessToken}`,
  };
};
