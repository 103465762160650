import React from 'react';
import { SourceIcon } from './source_icon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export type SourceProps = {
  name: string;
  icon: string;
  onSourceClick: (sourceName: string) => void;
};

export const SourceItem: React.FC<SourceProps> = ({ name, icon, onSourceClick }) => (
  <Box
    onClick={() => onSourceClick(name)}
    sx={{
      display: 'flex',
      gap: 2,
      alignItems: 'center',
      cursor: 'pointer',
      px: 4,
      py: 2,
      border: 1,
      borderColor: 'primary.light',
      borderRadius: 1,
      color: 'primary.main',
      fontWeight: 'bold',
      width: '100%',
      bgcolor: 'background.paper',
      '&:hover': {
        color: 'primary.dark',
        borderColor: 'primary.main',
        boxShadow: 3,
      },
    }}
  >
    <SourceIcon icon={icon} />
    <Typography variant="body1" noWrap>
      {name}
    </Typography>
  </Box>
);
