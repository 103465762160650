import React from 'react';
import Markdown from 'markdown-to-jsx';
import { Sources } from './sources';
import { ChatMessageType } from '../../../types';
import { Box, Typography, Stack } from '@mui/material';

interface AnswerMessageProps {
  text: ChatMessageType['content'];
  sources: ChatMessageType['sources'];
  onSourceClick: (source: string) => void;
}

export const AnswerMessage: React.FC<AnswerMessageProps> = ({ text, sources, onSourceClick }) => {
  return (
    <Stack spacing={4} sx={{ mb: 4 }}>
      <Box>
        <Stack spacing={1}>
          <Typography variant="h4" color="text.primary" fontWeight="bold">
            Answer
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" fontWeight="medium">
            Powered by{' '}
            <Box component="span" fontWeight="bold">
              MX2
            </Box>
          </Typography>
        </Stack>
      </Box>
      {text && (
        <Box sx={{ whiteSpace: 'pre-wrap' }}>
          <Markdown>{text}</Markdown>
        </Box>
      )}
      {sources && <Sources showDisclaimer sources={sources} onSourceClick={onSourceClick} />}
    </Stack>
  );
};
